import Vue from 'vue';
import App from './App';
import { router } from './router';
import BaiduMap from 'vue-baidu-map'
import { NavBar } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(NavBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: '2SSfxETqwZ101TSdQ5kejRZZZTfPL7bR'
})
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
